<!--我的消息-->
<template>
<div class="charting" @click="stopEvent()" style="height:100%; position:relative;">
    <!--好友列表-->
    <el-col :xs="24" :sm="6" class="left-box">
        <div class="my-info" v-if="user">
            <div class="photo-wrap my-photo-wrap"  
                    @click="showUserView()"
                    :style="{overflow: (user.photo || user.photoId)?'hidden': 'visible', 'cursor': 'pointer'}">
                <img v-if="user.photo || user.photoId" :src="imgUrl+(user.photo || user.photoId)" >
                <div class="text-photo" v-else>{{user.firstName}}</div>
            </div>
            <span class="my-name">{{user.fullname}}</span>
        </div>
        <div class="search-wrap">
            <el-input size="small" :placeholder="msg('搜索好友')" v-model="searchInput" class="search-input"
                clearable @clear="serachUser"  @input="serachUser"
            >
                <el-button slot="append" icon="el-icon-search" @click="serachUser"></el-button>
            </el-input>
            <el-link :underline="false" class="add-friend" type="default" @click="prepareAdd()">
                <i class="iconfont icon-add font22" ></i>
            </el-link>
        </div>
        <el-scrollbar class="hidden-x"  :style="{height: (docHeight - 170)+'px', overflow: 'none'}">
            <div class="friends">
                <template v-for="(item, idx) in friends" >
                    <div @click="clickUserItem(item)" :key="idx" v-show="item.show"
                        :class="['fitem', item.active ? 'active':'']" 
                    >   
                        <div class="fitem-photo photo-wrap" @click="clickPhoto($event, item)" :style="{overflow: item.photoId?'hidden': 'visible'}">
                            <img v-if="item.photoId" :src="imgUrl+item.photoId" >
                            <div class="text-photo" v-else>{{item.firstName}}</div>
                        </div>
                        <span class="fname">{{item.showName}}</span>
                        <span v-if="item.total" class="badge" @click.prevent.stop="markRead(item)" >{{item.total}}</span>
                    </div>
                </template>
                <div v-if="friends.length == 0" class="no-data"  :style="{height: (docHeight - 220)+'px'}">
                    {{msg('您还没有好友')}}
                </div>
            </div> 
        </el-scrollbar>
    </el-col>
    <!--end 好友列表-->

    <!--聊天框-->
    <transition name="my-animate"  
            enter-active-class="animate__animated animate__fadeInRight animate__faster" 
            leave-active-class="animate__animated animate__fadeOutRight animate__faster">
        <el-col :xs="24" :sm="18" :class="['right-box', isMinScreen?'min-charting':'']" v-if="!isMinScreen || visiableCharting">
            <div class="chart-title-wrap">
                <el-link :underline="false" type="primary" @click="closeCharting()" v-if="isMinScreen && visiableCharting">
                    <i class="el-icon-back font22" ></i>
                </el-link>
                <div class="chart-title">{{chartingTitle}}</div>
            </div>
            <el-scrollbar :style="{height: (docHeight - (isMinScreen?200: 270))+'px'}" ref="msgBoxScroll" class="hidden-x">
                <div class="msg-box">
                    <div class="top-warning" @click="getMoreMessage">{{isMoreMessage?msg("点击加载更多>>>"):msg("没有更多消息了..")}}</div>
                    <template v-for="(m, idx) in messages">
                        <div v-if="m.fromId == activeItem.id" class="msg msg-to" :key="idx">
                            <div class="to-photo photo-wrap" :style="{overflow: activeItem.photoId?'hidden': 'visible'}">
                                <img v-if="activeItem.photoId" :src="imgUrl+activeItem.photoId" >
                                <div class="text-photo" v-else>{{activeItem.firstName}}</div>
                            </div>
                            <div class="msg-txt-wrap">
                                <div class="msg-fullname">{{activeItem.showName}}</div>
                                <div class="msg-fullname">{{m.created}}</div>
                                <div class="msg-txt" @click="clickMsg($event, m)" v-if="m.type == 0">{{m.message}}</div>
                                <div class="msg-img" @click="clickMsg($event, m)" v-else-if="m.type == 1" >
                                    <img :src="imgUrl + m.message" alt="">
                                </div>
                                <div class="msg-img" @click="clickMsg($event, m)" v-else-if="m.type == 2" >
                                    <span v-html="m.message"></span>
                                </div>
                            </div>
                        </div>
                        <div v-if="m.fromId == user.id" class="msg msg-from" :key="idx">
                            <div class="from-photo photo-wrap" :style="{overflow: user.photo?'hidden': 'visible'}">
                                <img v-if="user.photo" :src="imgUrl+user.photo" >
                                <div class="text-photo" v-else>{{user.firstName}}</div>
                            </div>
                            <div class="msg-txt-wrap">
                                <div class="msg-fullname">{{user.fullname}}</div>
                                <div class="msg-fullname">{{m.created}}</div>
                                <div class="msg-txt" @click="clickMsg($event, m)" v-if="m.type == 0">{{m.message}}</div>
                                <div class="msg-img" @click="clickMsg($event, m)" v-else-if="m.type == 1" >
                                    <img :src="imgUrl + m.message" alt="">
                                </div>
                                <div class="msg-img" @click="clickMsg($event, m)" v-else-if="m.type == 2" >
                                    <span v-html="m.message"></span>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </el-scrollbar>
            <div class="chart-tool">
                <el-link :underline="false" type="default" @click="uploadFile">
                    <i class="iconfont icon-seiyw41 font22" ></i>
                </el-link>
                <el-link :underline="false" type="default" v-popover:emojiPopover>
                    <i class="iconfont icon-biaoqing font22" ></i>
                </el-link>
            </div>
            <div class="inputing-box"> 
                <!-- <el-input type="textarea" :placeholder="this.msg('按Enter+Ctrl发送')"
                    v-model="sendMsg" maxlength="200" :rows="isMinScreen?2:5" show-word-limit @keyup.native="inputSendMsg"
                    @paste.native.capture.prevent="handlePaste"
                    >
                </el-input> -->
                <div class="chatText" ref="editDiv" contenteditable="true" :placeholder="this.msg('按Enter+Ctrl发送')"
                    v-on:keyup="inputSendMsg" v-on:paste.capture.prevent="handlePaste"
                    ></div>
                <el-button type="primary" @click="prepareSendMessage" size="small"><i class="iconfont icon-xunzhao03"></i>{{msg('发送')}}</el-button>
            </div>
        </el-col>
    </transition>
    <!--end 聊天框-->

    <!--点击用户头像的弹窗-->
    <div class="animate__animated animate__fadeIn animate__faster el-popover el-popper user-ctrl-pop" 
            ref="userCtrlPop" v-show="userCtrlPopVisiable"
            x-placement="bottom"
            >
        <div class="user-ctrl">
            <el-link class="ctrl-item" :underline="false" type="default" @click="updateRemark">
                <i class="iconfont icon-edit font18" ></i>
                <span style="display:inline-block; margin-left:5px;">{{msg('修改备注')}}</span>
            </el-link>
            <el-link class="ctrl-item" :underline="false" type="default" @click="viewPhoto">
                <i class="iconfont icon-seiyw41 font18" ></i>
                <span style="display:inline-block; margin-left:5px;">{{msg('查看照片')}}</span>
            </el-link>
            <el-link class="ctrl-item" :underline="false" type="default" @click="deleteFriend">
                <i class="iconfont icon-delete font18" ></i>
                <span style="display:inline-block; margin-left:5px;">{{msg('删除好友')}}</span>
            </el-link>
        </div>
        <div x-arrow="" class="popper__arrow" style="left: 54px;"></div>
    </div>
    <!--点击消息内容的弹窗-->
    <div class="animate__animated animate__fadeIn animate__faster el-popover el-popper user-ctrl-pop" 
            ref="msgCtrlPop" v-show="msgCtrlPopVisiable"
            x-placement="bottom"
        >
        <div class="user-ctrl">
            <el-link class="ctrl-item" v-if="currMsg && currMsg.type == 1" 
                    @click="showMsgPhoto()"
                    :underline="false" type="default" >
                <i class="iconfont icon-seiyw41 font18" ></i>
                <span style="display:inline-block; margin-left:5px;">{{msg('查看图片')}}</span>
            </el-link>
            <el-link class="ctrl-item" :underline="false" type="default" @click="deleteMsg()">
                <i class="iconfont icon-delete font18" ></i>
                <span style="display:inline-block; margin-left:5px;">{{msg('删除消息')}}</span>
            </el-link>
        </div>
        <div x-arrow="" class="popper__arrow" style="left: 54px;"></div>
    </div>

    <!--添加好友-->
    <el-dialog :title="msg('添加好友')"
            :close-on-click-modal="false" :visible.sync="addFriendDbVisiable" :fullscreen="isMinScreen" width="500px">
        <div class="add-dg">
            <div style="font-size:10px;margin-top:-25px;margin-bottom:10px;"><span>&nbsp;&nbsp;&nbsp;&nbsp;请在下方搜索框内输入要添加的好友的名字或账号，输入完成后点击搜索按钮，搜索出好友后双击好友头像将对方添加成好友即可进行在线聊天</span></div>
            <div style="display:flex;">
                <el-input size="small" :placeholder="msg('搜索用户')" v-model="addSerachInput">
                    <el-button slot="append" icon="el-icon-search" @click="doAddSearch()"></el-button>
                </el-input>
                <el-button type="primary" size="mini" style="margin-left:15px;" @click="doAdd(null)"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            </div>
            <el-scrollbar class="hidden-x"  style="height:400px;">
                <div class="friends">
                    <div v-for="(item, idx) in searchResult" :key="idx" @click="clickSearchResult(item)"
                        @dblclick="doAdd(item)"
                        :class="['fitem', item.active ? 'active':'']" 
                    >
                        <div class="fitem-photo photo-wrap" :style="{overflow: item.key?'hidden': 'visible'}">
                            <img v-if="item.key && item.key!='null' && item.key != 0" :src="imgUrl+item.key" alt="">
                            <div v-else class="text-photo" >{{item.value.substring(0,1).toUpperCase()}}</div>
                        </div>
                        <span class="fname">{{item.value}}</span>
                    </div>
                </div>
            </el-scrollbar>
        </div>
     </el-dialog>

    <!--查看好友头像-->
    <el-image-viewer v-if="showViewer" :on-close="()=>{this.showViewer = false}" :url-list="[userPhotoUrl]" />
    
    <el-popover
        ref="emojiPopover"
        placement="top"
        width="300"
        trigger="click"
        v-model="emojiPopoverVisiable"
        > 
        <el-scrollbar class="hidden-x"  style="height:200px;">
            <ul class="icon-list">
                <li v-for="(emoji, idx) in emojis" :key="idx" @click="clickEmoji(emoji)">
                    {{emoji}}
                </li>
            </ul>
        </el-scrollbar>
    </el-popover>

    <div class="temp" style="display:none;" ref="temp">

    </div>
</div>
</template>

<script>
import 'animate.css';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
    name: "Chart",
    components: { 
        ElImageViewer: ElImageViewer,
    },
    mounted(){
        let u  = JSON.parse(localStorage.getItem("user"));
        u['firstName'] = u.fullname.substring(0,1).toUpperCase();
        this.user = u;
        this.getFriends();
        this.getTotalTimer();
        this.visiableCharting = this.mi
    },
    data(){
        return {
            userCtrlPopVisiable: false,  //点击用户头像，显示和隐藏
            user: null, //登录用户
            imgUrl: this.$kit.api.imgView,
            searchInput: null, //搜索用户的搜索框
            addSerachInput:null, //添加好友，搜索好友的输入框
            addFriendDbVisiable: false, //条加好友的弹窗
            visiableCharting: true, //是否打开聊天窗口
            messages: [], //消息列表
            isMoreMessage: false,  //是否有更多的消息
            
            sendMsg: null,  //待发消息的输入框
            friends: [],  //好友列表
            activeItem: null,  //当前选中的用户
            activeTimer: null, //当点击用户以后，定是获取用户的最新消息
            totalTimer:null, //获取消息总数的定时器
            duration: 60000,  //获取消息的间隔
            searchResult: [], //搜索到的待添加的用户
            
            userCtrlPopEl: null, //点击用户头像的弹窗引用
            userCtrlPopItem: null, //点击用户头像的弹窗的item

            showViewer: false,  //查看图片
            userPhotoUrl: null,
            chartingTitle: null, //fmt("与 %s 聊天中...", ['就发了甲方'])
        
            msgCtrlPopVisiable: false, //点击消息的下拉菜单
            currMsg: null,  //点击消息后，的消息内容
            msgCtrlPopEl: null,
            emojis:[
                "😀","😁","😂","😃","😄","😅","😆","😉","😊","😋","😎","😍","😘","😗",
                "😙","😚","☺","😇","😐","😑","😶","😏","😣","😥","😮","😯","😪","😫","😴",
                "😌","😛","😜","😝","😒","😓","😔","😕","😲","😷","😖","😞","😟","😤",
                "😢","😭","😦","😧","😨","😬","😰","😱","😳","😵","😡","😠","🌹","🍀",
                "🍎","💰","📱","🌙","🍁","🍂","🍃","🌷","💎","🔪","🔫","🏀","⚽","⚡",
                "👄","👍","🔥","👦","👧","👨","👩","👴","👵","👶","👱","👮","👲","👳",
                "👷","👸","💂","🎅","👰","👼","💆","💇","🙍","🙎","🙅","🙆","💁","🙋",
                "🙇","🙌","🙏","👤","👥","🚶","🏃","👯","💃","👫","👬","👭","💏","💑",
                "👪","💪","👈","👉","☝","👆","👇","✌","✋","👌","👍","👎","✊","👊","👋",
                "👏","👐","✍","💘","❤","💓","💔","💕","💖","💗","💙","💚","💛","💜",
                "💝","💞","💟","❣","💐","🌸","💮","🌹","🌺","🌻","🌼","🌷","🌱","🌿","🍀"
            ],
            emojiPopoverVisiable: false,

            remarkTxt: null, //备注，用于弹窗输入

            chatImgId:null,//粘贴的截图Id
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                if(!is){
                    this.openCharting();
                }
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight - 40;
                return height;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    created() {
    },
    methods:{
        getFriends(){ //获取好友列表
            this.$axios.get(this.$kit.api.user.friends)
                .then((result) => {
                    if(result.status){
                         result.data.forEach(e => {
                            e["active"] = false;
                            e['show'] = true;
                            e['total'] = 0;
                            e['showName'] =e.remark?(e.fullname+"("+e.remark+")"): e.fullname;
                            e['firstName'] = e.fullname.substring(0,1).toUpperCase();
                        });
                        this.friends = result.data;
                        this.getTotals();
                    }
            });
        },
        getTotalTimer(){ //获取消息总数
            let _this = this;
            this.totalTimer = setInterval(() => {
                if(window.location.href.indexOf('chat')>-1){
                    _this.getTotals(); 
                }
            }, this.duration);
        },
        getTotals(){ //获取好友消息
            this.$axios.get(this.$kit.api.message.totalGroupUnread)
                .then((result) => {
                    if(result.status){
                        result.data.forEach(e => {
                            let friend = this.friends.find(f => f.id == e.id);
                            if(friend){
                                friend.total = e.value;
                            }

                        })
                    }
            });
        },
        openCharting(){ //打开聊天窗口
            this.visiableCharting = true;
        },
        closeCharting(){ //关闭聊天窗口
            this.visiableCharting = false; 
        },

        friendClick(){  //点击好友列表的每一条
            this.openCharting()
        },
        
        clickUserItem(item){ //点击好友列表的每一条
            if(this.activeItem && this.activeItem.id == item.id){
                return;
            }

            var active = !item.active;
            if(this.activeItem){
                this.activeItem.active = false;
            }
            this.activeItem = item;
            this.openCharting()
            item.active = active;
            this.chartingTitle = this.fmt("与 %s 聊天中...", [item.showName]) 

            //获取消息内容
            item['start'] = 0;
            item['length'] = 100;
            item['lastMsgId'] = 0;
            // item['total'] = 0;

            if(this.activeTimer){
                clearInterval(this.activeTimer);
            }

            this.messages = [];
            this.getMessages(item, false, ()=>{
                this.activeTimer = setInterval(()=>{
                    this.getMessages(item, false);
                }, this.duration);
            });

        },
        //获取消息内容
        getMessages(friend, prev, cb){ //prev: true: 表示将接受到的消息插入到前面
            
            let para = "?id="+friend.id 
                    + "&s=" + (friend['start'] || 0) 
                    + "&l=" + (friend['length'] || 100)
                    + (friend['lastMsgId'] !== 0?("&last="+friend['lastMsgId']):"");
            
            this.$axios.get(this.$kit.api.message.userList + para)
                .then((result) => {
                    if(result.status){
                        this.isMoreMessage = result.data.length>0
                        if(prev){
                            for(let i=result.data.length-1; i>=0; i--){
                                this.messages.unshift(result.data[i]);
                            }
                        }else{
                            result.data.forEach(e => {
                                this.messages.push(e);
                            })
                        }
                        if(result.data.length>0){
                            friend["lastMsgId"] = result.data[result.data.length-1].id;
                        }
                        if(!prev){
                            this.scrollerBottom();
                        }
                        if(cb){
                            cb();
                        }
                    }
            });
        },

        //点击添加好友按钮
        prepareAdd(){
            this.addSerachInput = null;
            this.searchResult = [];
            this.addFriendDbVisiable = true;
        },
        //确定添加好友
        doAdd(item){

            if(!item){
                item = this.searchResult.find(e => e.active);
            }
            if(!item){
                this.$message(this.msg('请选择一个用户'));
                return;
            }

            var _this = this;
            this.$prompt(this.msg('设置备注和标签'), this.msg('确定要添加对方为好友？'), {
                customClass: this.isMinScreen?'input-min-box':'',
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
            }).then(({ value }) => {
                    _this.$axios.post(_this.$kit.api.user.addFriend, {
                         friendId: item.id,
                         remark: value?value: ''
                    }).then((result) => {
                        if(result.status){
                            _this.$message(result.msg || _this.msg("添加成功"));
                            _this.getFriends();
                        }else{
                             _this.$message(result.msg || _this.msg("添加失败")); 
                        }
                    });
                }).catch(() => {
                    _this.$message('取消输入');       
            });
        },
        doAddSearch(){ //搜索好友
            if(!this.addSerachInput){
                return;
            }

            this.$axios.post(this.$kit.api.user.search+"?name="+encodeURIComponent(this.addSerachInput)+"&fullname=true")
            .then((result) => {
                    if(result.status && result.data.length>0){

                        result.data.forEach(e => {
                            e["active"] = false;
                            if(e.key == 'null'){
                                e.key = null;
                            }
                            this.searchResult = result.data;
                        });
                    }else{
                        this.searchResult = [];
                        this.$message(this.msg('该好友不存在'));   
                    }
            });

        },
        clickSearchResult(item){
            this.searchResult.forEach(e => {
                e.active = false;
            });
            item.active = true;
        },
        clickPhoto(e, item){ //点击好友列表头像
            //this.userCtrlPopVisiable = true;
            e.stopPropagation();
            var pop = this.$refs.userCtrlPop;

            var rec = e.target.getBoundingClientRect();

            let left = rec.x - e.target.offsetWidth + 40 || e.clientX-50;
            let top = rec.y + e.target.offsetHeight || e.clientY+30;
            pop.style.cssText="display:block; left: "+left+"px; top:"+top+"px";

            document.body.appendChild(pop);

            this.userCtrlPopEl = pop;
            this.userCtrlPopItem = item;

        },
        stopEvent(){ //隐藏好友pop
            if(this.userCtrlPopEl){
                this.$refs.userCtrlPop.style="display:none;"
            }
            if(this.msgCtrlPopEl){
                this.$refs.msgCtrlPop.style="display:none;";
            }
        },
        updateRemark(){ //修改好友备注
            if(this.userCtrlPopItem){
                var _this = this;
                this.$prompt(this.msg('请输入备注'), this.msg('提示'), {
                    customClass: this.isMinScreen?'input-min-box':'',
                    confirmButtonText: this.msg('确定'),
                    cancelButtonText: this.msg('取消'),
                }).then(({ value }) => {
                        let para = "?id="+this.userCtrlPopItem.id+"&remark="+encodeURIComponent(value);
                        _this.$axios.get(_this.$kit.api.user.remarkFriend+para).then((result) => {
                            if(result.status){
                                _this.$message(result.msg || _this.msg("更新成功"));
                                _this.getFriends();
                            }else{
                                _this.$message(result.msg || _this.msg("更新失败")); 
                            }
                        });
                    }).catch(() => {
                        _this.$message('取消输入');       
                });
                this.$refs.userCtrlPop.style="display:none;"
            }
            
        },
        viewPhoto(){ //查看好友照片
            if(this.userCtrlPopItem && this.userCtrlPopItem.photoId){
                this.showView(this.userCtrlPopItem.photoId);
            }
            this.$refs.userCtrlPop.style="display:none;"
        },
        showView(photoId){
            this.userPhotoUrl = this.imgUrl + photoId;
            this.showViewer = true;
        },
        showUserView(){ //查看自己照片
            if(this.user.photo || this.user.photoId) {
                this.showView(this.user.photo || this.user.photoId);
            }
        },
        deleteFriend(){ //删除好友
            if(this.userCtrlPopItem){
                var _this = this;
                this.$confirm(this.fmt('确定要删除好友《%s》吗?', [this.userCtrlPopItem.fullname]), this.msg('提示'), {
                    confirmButtonText: this.msg('确定'),
                    cancelButtonText: this.msg('取消'),
                    type: 'warning'
                }).then(() => {
                    _this.$axios.get(_this.$kit.api.user.delFriend+"?id="+_this.userCtrlPopItem.id)
                        .then((result) => {
                            _this.$message(result.msg || _this.msg('删除成功'));
                            _this.getFriends();
                            
                        });
                }).catch((err) => {
                // console.log(err);
            });
            }
            this.$refs.userCtrlPop.style="display:none;"
        },
        //监听消息框的输入时间
        inputSendMsg(e){
            if (event.ctrlKey && event.keyCode == 13) {
                this.prepareSendMessage(e);
            }
        },
        prepareSendMessage(e){

            let text = this.$refs.editDiv.innerText;

            this.sendMsg = text;
            if(!text){
                this.$message(this.msg('请输入要发送的内容'));
                return;
            }

            if(!this.activeItem){
                this.$message(this.msg('请选择一个好友'));
                return;
            }

            //添加到聊天框
            let msg = {
                fromId: this.user.id,
                toId: this.activeItem.id,
                message: this.sendMsg,
                type: 0,
            }

            this.doSendMessage(msg, () => {
                this.sendMsg = msg.message;
            });
        },
        doSendMessage(message, failCb){
            this.messages.push(message);
            this.$refs.editDiv.innerText = "";

            //保存到服务器
            this.sendMsg = "";
            this.$axios.post(this.$kit.api.message.send, message)
                .then((result) => {
                    if(!result.status){
                        this.$message(result.msg);
                        this.messages.splice(this.messages.length-1);
                        if(failCb) failCb();
                    }else{
                        message['id'] = result.data; 
                        this.activeItem['lastMsgId'] = result.data;
                        this.scrollerBottom();
                    }
                });
        },
        scrollerBottom() { //滚动到底部
            var _this = this;
            setTimeout(()=>{
                let msgScoller = _this.$refs.msgBoxScroll;
                msgScoller.wrap.scrollTop = msgScoller.wrap.scrollHeight
            }, 100);
        },
        getMoreMessage(){  //查看更多消息
            
            if(this.activeItem && this.isMoreMessage){
                let start = this.activeItem['start'];
                let length = this.activeItem['length'];

                this.activeItem['start'] = (start+1)*length;
                this.getMessages(this.activeItem, true);
            }
        },
        clickMsg(e, msg){ //点击消息
            //this.userCtrlPopVisiable = true;
            e.stopPropagation();
            var pop = this.$refs.msgCtrlPop;

            var rec = e.target.getBoundingClientRect();
            let left = rec.x + e.target.offsetWidth/2 - 60 || e.clientX-50;
            let top = rec.y + e.target.offsetHeight || e.clientY+30;
            pop.style.cssText="display:block; left: "+left+"px; top:"+top+"px";

            document.body.appendChild(pop);

            this.msgCtrlPopEl = pop;
            this.currMsg = msg;
        },
        deleteMsg(){
            //删除消息
            this.$axios.get(this.$kit.api.message.deleteByUser+"?id="+this.currMsg.id)
                .then((result) => {
                    this.$message(result.msg);
                    if(result.status){
                        let idx = this.messages.findIndex(m => m.id == this.currMsg.id);
                        if(idx>-1){
                            this.messages.splice(idx, 1);
                        }
                    }
                });
            this.$refs.msgCtrlPop.style="display:none;";
        },
        showMsgPhoto(){ //查看消息类型的图片
            this.showView(this.currMsg.message); 
            this.$refs.msgCtrlPop.style="display:none;";
        },
        clickEmoji(emoji){ //选中emoji
            if(this.$refs.editDiv.innerText){
                // this.sendMsg += emoji;
                this.$refs.editDiv.innerText += emoji;
            }else{
                // this.sendMsg = emoji;
                this.$refs.editDiv.innerText = emoji;
            }
            this.emojiPopoverVisiable = false;
        },
        uploadFile(){  //点击上传文件
            if(!this.activeItem){
                this.$message(this.msg('请选择一个好友'));
                return;
            }
            var _this = this;
            var inputFile = document.createElement("input");
            inputFile.type = "file";
            inputFile.style.display="none";
            //inputFile.accept = "image/gif, image/jpeg, image/png";
            inputFile.onchange =  function(e){
                e.preventDefault();

                let file = e.target.files[0];
                let size = file.size / 1024 / 1024;
                if(size>10){
                    _this.$message(_this.msg('不能操作10M'));
                    return;
                }
                let formData = new FormData();
                formData.append('file',file);
                _this.axios.post(_this.$kit.api.upload+"?auth=1&type=chat", 
                    formData, 
                    {headers:{'Content-Type':'multipart/form-data'}}
                ).then((result) => {
                    if(result.status){

                        let fileType = file.type.toLowerCase();
                        let msgType = 2;
                        if(fileType.indexOf('gif') > -1 || fileType.indexOf('jpg') > -1
                            || fileType.indexOf("jpeg") > -1 || fileType.indexOf("png") >-1) {
                                msgType = 1;
                        }

                        let msgContent = null;
                        if(msgType == 1){
                            msgContent = result.data;
                        }else{
                            msgContent = "<a _target='blank' href='"+_this.$kit.api.imgView+result.data+"'>"+file.name+"</a>";
                        }

                        _this.doSendMessage({
                            fromId: _this.user.id,
                            toId: _this.activeItem.id,
                            type: msgType,
                            message: msgContent
                        });
                    }else{
                        _this.$message(_this.msg('发送失败'));
                    }
                }).catch(function (error) {
                    console.error(error);
                });
                _this.$refs.temp.removeChild(e.target);
            };

            this.$refs.temp.appendChild(inputFile);
            inputFile.click();

        },
        //标记为已读
        markRead(item){
            this.$axios.get(this.$kit.api.message.markRead+"?id="+item.id)
                .then((result) => {
                    this.$message(result.msg);
                    if(result.status){
                        item.total = 0;
                    }
                });
        },
        //搜索用户
        serachUser(){

            this.friends.forEach(f => {

                if(this.searchInput){
                    if(f.showName.indexOf(this.searchInput) == -1){
                        f.show = false;
                    }else{
                        f.show = true;
                    }
                }else{
                    f.show = true;
                }

            });

        },
        //监听粘贴事件
        handlePaste(event){
            var isChrome = false;
            var _this = this;
            if ( event.clipboardData || event.originalEvent ) {
                //not for ie11  某些chrome版本使用的是event.originalEvent
                var clipboardData = (event.clipboardData || event.originalEvent.clipboardData);
                if ( clipboardData.items ) {
                    // for chrome
                    var  items = clipboardData.items,
                        len = items.length,
                        blob = null;
                    isChrome = true;
                    //items.length比较有意思，初步判断是根据mime类型来的，即有几种mime类型，长度就是几（待验证）
                    //如果粘贴纯文本，那么len=1，如果粘贴网页图片，len=2, items[0].type = 'text/plain', items[1].type = 'image/*'
                    //如果使用截图工具粘贴图片，len=1, items[0].type = 'image/png'
                    //如果粘贴纯文本+HTML，len=2, items[0].type = 'text/plain', items[1].type = 'text/html'
                    // console.log('len:' + len);
                    // console.log(items[0]);
                    // console.log(items[1]);
                    // console.log( 'items[0] kind:', items[0].kind );
                    // console.log( 'items[0] MIME type:', items[0].type );
                    // console.log( 'items[1] kind:', items[1].kind );
                    // console.log( 'items[1] MIME type:', items[1].type );

                    if(items[0].type.indexOf("text") > -1){
                        _this.$refs.editDiv.innerText += clipboardData.getData("text");
                        return;
                    }
                
                    //阻止默认行为即不让剪贴板内容在div中显示出来
                    event.preventDefault();
                    //在items里找粘贴的image,据上面分析,需要循环  
                    for (var i = 0; i < len; i++) {
                        if (items[i].type.indexOf("image") !== -1) {
                    
                            //getAsFile() 此方法只是living standard firefox ie11 并不支持        
                            blob = items[i].getAsFile();
                        }
                    }
                    if ( blob !== null ) {
                        var reader = new FileReader();
                        reader.onload = function (event) {
                            // event.target.result 即为图片的Base64编码字符串
                            var base64_str = event.target.result
                            //可以在这里写上传逻辑 直接将base64编码的字符串上传（可以尝试传入blob对象，看看后台程序能否解析）
                            // console.log('base64_str',base64_str);
                            _this.uploadImgFromPaste(base64_str);
                        }
                        reader.readAsDataURL(blob); 
                    }
                    
                } else {
                    //for firefox
                    setTimeout(function () {
                        //设置setTimeout的原因是为了保证图片先插入到div里，然后去获取值
                        var imgList = document.querySelectorAll('#tar_box img'),
                        len = imgList.length,
                        src_str = '',
                        i;
                        for ( i = 0; i < len; i ++ ) {
                            if ( imgList[i].className !== 'my_img' ) {
                                //如果是截图那么src_str就是base64 如果是复制的其他网页图片那么src_str就是此图片在别人服务器的地址
                                src_str = imgList[i].src;
                            }
                        }
                        // this.uploadImgFromPaste(src_str, 'paste', isChrome);
                    }, 1);
                }
            } else {
                //for ie11
                setTimeout(function () {
                    var imgList = document.querySelectorAll('#tar_box img'),
                        len = imgList.length,
                        src_str = '',
                        i;
                    for ( i = 0; i < len; i ++ ) {
                        if ( imgList[i].className !== 'my_img' ) {
                            src_str = imgList[i].src;
                        }
                    }
                    // this.uploadImgFromPaste(src_str, 'paste', isChrome);
                }, 1);
            }
        },
        uploadImgFromPaste (base64Img) {
            var _this = this;
            if(!_this.activeItem){
                _this.$message(_this.msg('请选择一个好友'));
                return;
            }
            this.$axios.post(this.$kit.api.uploadChatImg,{code:base64Img})
                .then((result) => {
                    if(result.status){
                        _this.chatImgId = result.data;
                        //添加到聊天框
                        let msg = {
                            fromId: _this.user.id,
                            toId: _this.activeItem.id,
                            message: result.data,
                            type: 1,
                        }

                        _this.doSendMessage(msg, () => {
                            _this.sendMsg = msg.message;
                        });
                    }else{
                        this.message(result.msg || "上传失败");
                    }
                });
        }
    }
}
</script>

<style scoped lang="scss">
.left-box{background: #E5E5E5; }
.my-info{
    padding: 10px; 
    display:flex;
    align-items: center;
    flex-wrap:nowrap;
}

.my-photo-wrap, .fitem-photo, .to-photo, .from-photo{flex: 0 0 40px; width:45px; height:45px; 
    border-radius: 50%; margin-right:10px; }
.my-photo-wrap img, .fitem-photo img, .to-photo img, .from-photo img{width:40px;}
.my-name{font-size:18px;}

.my-name, .fname, .chart-title{
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden; word-break: break-all;
}

.search-wrap{padding:0px 10px 10px 10px; display:flex; align-items: center;
box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
}
.search-wrap >>> .el-input-group__append{padding: 0px 8px;} 
.search-input{width: calc(100% - 30px); margin-right:5px;}
.add-friend i{font-size:29px !important;}

.fitem{display:flex; align-items: center; padding: 7px 10px; position:relative;}
.fitem:hover{background: #DBDBDB; cursor: pointer;}
.fitem.active{background: #C6C6C6;}
.badge{
    position: absolute;
    top: 3px;
    right: 10px;
    background: #F56C6C;
    color: white;
    border-radius: 11px;
    min-width: 18px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    padding:2px;
    cursor:pointer;
}

.fname{font-size:14px; color:#555555;}

.right-box{background: #F5F5F5;}
.chart-title-wrap{display:flex;  align-items: center; padding: 10px; box-sizing: content-box;
    border-bottom: 1px solid #E7E7E7; box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
}

.msg-box{padding-top:10px;padding-bottom:20px;} 
.msg{display:flex; }
.msg-txt{word-break: break-all; }
.msg-from{padding:5px 10px 5px 20%;}
.msg-to{padding: 5px 20% 5px 10px;} 
.msg-txt, .msg-img{
    border: 1px solid rgba(0,0,0,0.1); 
    border-radius: 6px; 
    padding:6px; font-size:18px;
}

.msg-to .msg-txt, .msg-to .msg-img{background:white;  color: #555555;  box-shadow: 0px 0px 10px rgba(0,0,0,0.15);}
.msg-from .msg-txt, .msg-from .msg-img{background:#66B1FF;   color: white;  box-shadow: 0px 0px 10px rgba(0,0,255,0.15);}

.msg-fullname{font-size:12px; color:#8a8a8a; margin-bottom:3px;}
.msg-from .msg-fullname{text-align:right;}

.to-photo{margin-top:10px;}
.from-photo{margin-top:18px;}
.from-photo{margin-left:10px;}

.msg-from{flex-direction: row-reverse;}

.msg-img{max-width:200px; overflow:hidden; } 
.msg-img img{max-width:200px;}

.chart-tool{
    box-shadow: 0 -4px 4px rgba(0,0,0, 0.1);
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,0.1); 
    border-top: 1px solid rgba(0,0,0,0.1);
}

.chart-tool /deep/  .el-link{margin-left:15px;}
.inputing-box{display:flex; align-items: center; padding: 0px 10px; margin-top:10px;}
.inputing-box /deep/  .el-button{flex: 0 0 80px; margin-left:10px;}

.min-charting{
    position: absolute; top:0px; left:0px; z-index: 10;
}
.photo-wrap{
   background: #9BA1DD; border-radius: 50%; border: 2px solid rgba(255,255,255, 0.8); 
   box-shadow: 0 0 10px rgba(0,0,0,0.3); min-width:45px;height:45px;  box-sizing: border-box;
}
.text-photo{display:inline-block; color: white; font-size:27px; font-weight:bold; text-align:center;
 width:45px;height:45px;  box-sizing: border-box;
}
.no-data{
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 22px;
    color: #aaa;
}

.user-ctrl{
    display:flex; 
    flex-direction: column;
}
.ctrl-item{padding:8px 0px;}
.user-ctrl-pop{min-width:100px;
    position: absolute;
}
.top-warning{
    background: #f0f9eb;
    color: #67c23a;
    text-align: center;
    font-size: 13px;
    padding: 5px;
    position: relative;
    top: -9px;
    cursor:pointer;
}
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li{font-size:18px;} 
.icon-list li:hover{background:rgba(239, 246, 235, 0.73);}
.chatText{
    height: 115px;
    width: 800px;
    border: 1px solid #ccc;
    overflow-y:auto;
}
.chatText:empty::before{
    color:lightgrey;
    content:attr(placeholder);
}
</style>